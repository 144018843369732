import React from "react";
import footerlogo from "../assets/images/footerlogo.png";
import twitterlogo from "../assets/images/twitter.png";
import planelogo from "../assets/images/plane.png";

function Footer() {
  // Scroll to section with offset
  // Scroll to section with offset

  // Function to determine padding adjustment based on viewport width
  const getPaddingAdjustment = () => {
    const viewportWidth = window.innerWidth;
    return viewportWidth < 600 ? -60 : 60; // Use 30 for less than 600px, 60 otherwise
  };

  // Scroll to section with offset
  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    const offset = 0; // Adjust this for your navbar height
    const paddingAdjustment = getPaddingAdjustment(); // Dynamically set padding adjustment

    if (element) {
      const elementPosition =
        element.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - offset + paddingAdjustment;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };

  return (
    <footer className="footer">
      <div className="sec-box">
        <div className="footer-row">
          <div className="footerlogo phonehidden">
            <img src={footerlogo} alt="" />
          </div>
          <div className="footermenu phonehidden">
            <ul>
              <li>
                <button
                  className="link-btn"
                  onClick={() => scrollToSection("home")}
                >
                  Home
                </button>
              </li>
              <li>
                <button
                  className="link-btn"
                  onClick={() => scrollToSection("about")}
                >
                  About
                </button>
              </li>
              <li>
                <button
                  className="link-btn"
                  onClick={() => scrollToSection("services")}
                >
                  Services
                </button>
              </li>
              <li>
                <button
                  className="link-btn"
                  onClick={() => scrollToSection("contact")}
                >
                  Contact
                </button>
              </li>
            </ul>
          </div>
          <div className="footericons phonehidden">
            <ul>
              <li>
                <a href="https://x.com/kronalabs">
                  <img src={twitterlogo} alt="" />
                </a>
              </li>
              <li>
                <a href="https://t.me/kronalabs">
                  <img src={planelogo} alt="" />
                </a>
              </li>
              <li>
                <p>© 2024 Krona Labs</p>
              </li>
            </ul>
          </div>

          <div className="footerlogo footericons showphone footerlogophone">
            <img src={footerlogo} alt="" />
            <ul>
              <li>
                <a href="https://x.com/kronalabs">
                  <img src={twitterlogo} alt="" />
                </a>
              </li>
              <li>
                <a href="https://t.me/kronalabs">
                  <img src={planelogo} alt="" />
                </a>
              </li>
            </ul>
          </div>
          <div className="footermenu showphone footermenuphone">
            <ul>
              <li>
                <button
                  className="link-btn"
                  onClick={() => scrollToSection("home")}
                >
                  Home
                </button>
              </li>
              <li>
                <button
                  className="link-btn"
                  onClick={() => scrollToSection("about")}
                >
                  About
                </button>
              </li>
              <li>
                <button
                  className="link-btn"
                  onClick={() => scrollToSection("services")}
                >
                  Services
                </button>
              </li>
              <li>
                <button
                  className="link-btn"
                  onClick={() => scrollToSection("contact")}
                >
                  Contact
                </button>
              </li>
            </ul>
          </div>
          <div className="footericons showphone footericonphone">
            <ul>
              <li>
                <p>© 2024 Krona Labs</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
