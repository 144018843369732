import React, { useState, useEffect, useRef } from "react";
import logo from "../assets/images/logo.png";
import arrowright from "../assets/images/arr-r.svg";
import ham from "../assets/images/ham.png";

function Navbar() {
  // State to control menu visibility
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Ref to track the menu and button
  const menuRef = useRef(null);
  const btnRef = useRef(null);

  // Function to toggle the menu visibility
  const toggleMenu = () => {
    setIsMenuOpen((prevState) => !prevState);
  };

  // Close the menu if clicked outside
  const handleClickOutside = (event) => {
    if (
      menuRef.current &&
      !menuRef.current.contains(event.target) &&
      !btnRef.current.contains(event.target)
    ) {
      setIsMenuOpen(false);
    }
  };

  // Add event listener for clicks outside when component mounts
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Function to determine padding adjustment based on viewport width
  const getPaddingAdjustment = () => {
    const viewportWidth = window.innerWidth;
    return viewportWidth < 600 ? -60 : 60; // Use 30 for less than 600px, 60 otherwise
  };

  // Scroll to section with offset
  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    const offset = 0; // Adjust this for your navbar height
    const paddingAdjustment = getPaddingAdjustment(); // Dynamically set padding adjustment

    if (element) {
      const elementPosition =
        element.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - offset + paddingAdjustment;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="navbar">
      <div>
        <img src={logo} alt="Logo" />
      </div>
      <div className="navbtns">
        <button ref={btnRef} className="outline-btn" onClick={toggleMenu}>
          <img src={ham} className="showphone ham" alt="" />{" "}
          <span className="phonehidden">Menu</span>
        </button>
        {isMenuOpen && (
          <ul ref={menuRef} className="menuslide">
            <li>
              <img src={arrowright} alt="" />{" "}
              <button
                className="link-btn"
                onClick={() => scrollToSection("about")}
              >
                About
              </button>
            </li>
            <li className="phonehidden">
              <img src={arrowright} alt="" />{" "}
              <button
                className="link-btn"
                onClick={() => scrollToSection("services")}
              >
                Services
              </button>
            </li>
            <li className="showphone">
              <img src={arrowright} alt="" />{" "}
              <button
                className="link-btn"
                onClick={() => scrollToSection("services")}
              >
                Services
              </button>
            </li>
            <li>
              <img src={arrowright} alt="" />{" "}
              <button
                className="link-btn"
                onClick={() => scrollToSection("contact")}
              >
                Contact
              </button>
            </li>
          </ul>
        )}
      </div>
    </div>
  );
}

export default Navbar;
