import React, { useEffect, useRef } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import stickybg1 from "../assets/images/sticky-bg1.png";
import stickybg2 from "../assets/images/sticky-bg2.png";
import stickybg3 from "../assets/images/sticky-bg3.png";
import stickybg4 from "../assets/images/sticky-bg4.png";
import star from "../assets/images/star.png";

const StickyBox = () => {
  const stickyBoxRef = useRef(null);
  const handleMouseMove = (e) => {
    const box = e.currentTarget;
    const glow = box.querySelector(".sticky-glow");

    const rect = box.getBoundingClientRect();
    const centerX = rect.left + rect.width / 1.5;
    const centerY = rect.top + rect.height / 1.5;

    const distanceX = e.clientX - centerX;
    const distanceY = e.clientY - centerY;

    // Adjust sensitivity with multipliers
    const translateX = -75.027 + distanceX * 0.3;
    const translateY = -299.979 + distanceY * 0.3;

    glow.style.transform = `translate3d(${translateX}px, ${translateY}px, 0px) scale3d(1, 1, 1)`;
  };

  const handleMouseLeave = (e) => {
    const glow = e.currentTarget.querySelector(".sticky-glow");
    glow.style.transform =
      "translate3d(-75.027px, -299.979px, 0px) scale3d(1, 1, 1)";
  };

  useEffect(() => {
    const ctx = gsap.context(() => {
      const stickyBoxes = gsap.utils.toArray(".sticky-box");

      stickyBoxes.forEach((box) => {
        const h6Element = box.querySelector("h6");

        // Create a writing effect for <h6>
        if (h6Element) {
          const text = h6Element.textContent;
          h6Element.innerHTML = ""; // Clear original text

          // Split text into spans for each character
          text.split("").forEach((char) => {
            const span = document.createElement("span");
            span.textContent = char;
            h6Element.appendChild(span);
          });

          // Animate each character
          gsap.fromTo(
            h6Element.querySelectorAll("span"),
            {
              opacity: 0,
              y: 20, // Start below
            },
            {
              opacity: 1,
              y: 0, // Move to original position
              duration: 1,
              ease: "power3.out",
              stagger: 0.05, // Delay between characters
              scrollTrigger: {
                trigger: box,
                start: "top 95%", // Start animation when the box is 95% from the top
                end: "top top", // End when the box is completely out of view
                scrub: 1,
                markers: false,
              },
            }
          );
        }

        // Animate the <h3> element (slide-in from the left)
        gsap.fromTo(
          box.querySelector(".h3"),
          {
            opacity: 0,
            x: -100, // Start from the left
          },
          {
            opacity: 1,
            x: 0, // End at the original position
            scrollTrigger: {
              trigger: box,
              start: "top 75%", // Start when the box is 75% from the top
              end: "top 20%", // End when the box is completely out of view
              scrub: 1,
              markers: false,
            },
          }
        );
        gsap.fromTo(
          box.querySelector(".h32"),
          {
            opacity: 0,
            x: -100, // Start from the left
          },
          {
            opacity: 1,
            x: 0, // End at the original position
            scrollTrigger: {
              trigger: box,
              start: "top 75%", // Start when the box is 75% from the top
              end: "top 20%", // End when the box is completely out of view
              scrub: 1,
              markers: false,
            },
          }
        );
      });
    }, stickyBoxRef);

    return () => ctx.revert(); // Cleanup GSAP context
  }, []);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const stickyBoxes = document.querySelectorAll(".sticky-box");
    const isMobile = window.innerWidth <= 768; // Mobile breakpoint

    stickyBoxes.forEach((box, index) => {
      // Adjust scroll trigger values based on the viewport
      const startValue = isMobile
        ? `top+=30% top` // Adjust staggered start positions for mobile
        : "top+=30% top";
      const endValue = isMobile
        ? `bottom top` // Adjust staggered end positions for mobile
        : "bottom top";

      gsap.fromTo(
        box,
        { scale: 1, filter: "brightness(100%) blur(0px)" },
        {
          scale: isMobile ? 0.95 : 0.9,
          filter: isMobile
            ? "brightness(70%) blur(3px)"
            : "brightness(50%) blur(5px)",
          scrollTrigger: {
            trigger: box,
            start: startValue,
            end: endValue,
            scrub: true,
            markers: false, // Disable after debugging
            onEnter: () => {
              gsap.to(box, {
                scale: isMobile ? 0.95 : 0.9,
                filter: isMobile
                  ? "brightness(70%) blur(3px)"
                  : "brightness(50%) blur(5px)",
                duration: 0.3,
              });
            },
            onLeave: () => {
              gsap.to(box, {
                scale: isMobile ? 0.95 : 0.9,
                filter: isMobile
                  ? "brightness(70%) blur(3px)"
                  : "brightness(50%) blur(5px)",
                duration: 0.3,
              });
            },
            onEnterBack: () => {
              gsap.to(box, {
                scale: isMobile ? 0.95 : 0.9,
                filter: isMobile
                  ? "brightness(70%) blur(3px)"
                  : "brightness(50%) blur(5px)",
                duration: 0.3,
              });
            },
            onLeaveBack: () => {
              gsap.to(box, {
                scale: 1,
                filter: "brightness(100%) blur(0px)",
                duration: 0.3,
              });
            },
          },
        }
      );
    });

    return () => {
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    };
  }, []);

  const stickyBoxData = [
    {
      id: 1,
      title: "Proven Expertise",
      description: "Years of experience building and scaling Web3 ventures.",
      image: stickybg1,
    },
    {
      id: 2,
      title: "Strategic Network",
      description: "Access to exchanges, launchpads, and industry partners.",
      image: stickybg2,
    },
    {
      id: 3,
      title: "End-to-End Support",
      description: "We align every phase of your project with your vision.",
      image: stickybg3,
    },
    {
      id: 4,
      title: "Focused on Results",
      description: "Designed to deliver measurable outcomes.",
      image: stickybg4,
    },
  ];

  return (
    <div ref={stickyBoxRef}>
      {stickyBoxData.map((box) => (
        <div
          className="sticky-box"
          onMouseMove={handleMouseMove}
          onMouseLeave={handleMouseLeave}
          key={box.id}
        >
          <div
            className="sticky-glow"
            style={{
              transform:
                "translate3d(-75.027px, -299.979px, 0px) scale3d(1, 1, 1)",
            }}
          ></div>
          <div className="sticky-box-top-content">
            <h6>WHY KRONA LABS</h6>
            <span className="linespan"></span>
            <img src={star} alt="Star" />
          </div>
          <div className="sticky-box-bottom-content">
            <div>
              <h3 className="showphone h32">{box.title}</h3>
            </div>
            <div>
              <img src={box.image} alt={box.title} />
            </div>
            <div>
              <h3 className="phonehidden h3">{box.title}</h3>
              <p>{box.description}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default StickyBox;
