import React from "react";
import logo1 from "../assets/images/partnerlogos/blum.png";
import logo2 from "../assets/images/partnerlogos/dotcooin.png";
import logo4 from "../assets/images/partnerlogos/binance-smart-chain-bsc-crypto-600nw-2199090473.png";
import logo5 from "../assets/images/partnerlogos/venom.png";
import logo6 from "../assets/images/partnerlogos/nebula.png";
import logo7 from "../assets/images/partnerlogos/chaingpt.png";
import logo8 from "../assets/images/partnerlogos/moonpay.png";
import logo9 from "../assets/images/partnerlogos/banxa.png";
import logo10 from "../assets/images/partnerlogos/Simplex-Nuvei-Logo-Negative.png";
import logo11 from "../assets/images/partnerlogos/15142.png";
import logo111 from "../assets/images/partnerlogos/holoworld.png";
import logo12 from "../assets/images/partnerlogos/55598990.png";
import logo13 from "../assets/images/partnerlogos/artworks-XwbVZe4s44yzt3Fb-q6b3TA-t500x500.png";
import logo14 from "../assets/images/partnerlogos/Cjn-logo-2015.png";
import logo15 from "../assets/images/partnerlogos/clip_finance_logo.png";
import logo16 from "../assets/images/partnerlogos/dotcooin.png";
import logo17 from "../assets/images/partnerlogos/download.png";
import logo18 from "../assets/images/partnerlogos/flowdesk5150.logowik.com.png";
import logo19 from "../assets/images/partnerlogos/ibc-group.png";
import logo20 from "../assets/images/partnerlogos/images-(1).png";
import logo21 from "../assets/images/partnerlogos/images-(2).png";
import logo22 from "../assets/images/partnerlogos/images-(3).png";
import logo23 from "../assets/images/partnerlogos/images-(4).png";
import logo24 from "../assets/images/partnerlogos/images-(4).png";
import logo25 from "../assets/images/partnerlogos/images.png";
import logo26 from "../assets/images/partnerlogos/logo-new.png";
import logo27 from "../assets/images/partnerlogos/logo-dark.png";
import logo28 from "../assets/images/partnerlogos/main-logo.bbf4da3.png";
import logo29 from "../assets/images/partnerlogos/meta-logo.png";
import logo31 from "../assets/images/partnerlogos/Simplex-Nuvei-Logo-Negative.png";
import logo32 from "../assets/images/partnerlogos/solidus-logo-transparent-white-small-246x300.png";
import logo33 from "../assets/images/partnerlogos/t_coin3606234.png";
import logo34 from "../assets/images/partnerlogos/token_logo_protokols.png";
import logo35 from "../assets/images/partnerlogos/trust-wallet-logo-png_seeklogo-521179.png";
import logo36 from "../assets/images/partnerlogos/images2.png";
import logo37 from "../assets/images/partnerlogos/images3.png";

function Marquee() {
  return (
    <div className="marquee-container">
      <div className="marquee">
        <img src={logo1} alt="" />
        <img src={logo2} alt="" />
        <img src={logo4} alt="" />
        <img src={logo5} alt="" />
        <img src={logo6} alt="" />
        <img src={logo7} alt="" />
        <img src={logo8} alt="" />
        <img src={logo9} alt="" />
        <img src={logo10} alt="" />

        <img src={logo11} alt="" />
        <img src={logo111} alt="" />
        <img src={logo12} alt="" />
        <img src={logo13} alt="" />
        <img src={logo14} alt="" />
        <img src={logo15} alt="" />
        <img src={logo16} alt="" />
        <img src={logo17} alt="" />
        <img src={logo18} alt="" />
        <img src={logo19} alt="" />
        <img src={logo20} alt="" />
        <img src={logo21} alt="" />
        <img src={logo22} alt="" />
        <img src={logo23} alt="" />
        <img src={logo24} alt="" />
        <img src={logo25} alt="" />
        <img src={logo26} alt="" />
        <img src={logo27} alt="" />
        <img src={logo28} alt="" />
        <img src={logo29} alt="" />

        <img src={logo31} alt="" />
        <img src={logo32} alt="" />
        <img src={logo33} alt="" />
        <img src={logo34} alt="" />
        <img src={logo35} alt="" />
        <img src={logo36} alt="" />
        <img src={logo37} alt="" />
        <img src={logo1} alt="" />
        <img src={logo2} alt="" />
        <img src={logo4} alt="" />
        <img src={logo5} alt="" />
        <img src={logo6} alt="" />
        <img src={logo7} alt="" />
        <img src={logo8} alt="" />
        <img src={logo9} alt="" />
        <img src={logo10} alt="" />

        <img src={logo11} alt="" />
        <img src={logo111} alt="" />
        <img src={logo12} alt="" />
        <img src={logo13} alt="" />
        <img src={logo14} alt="" />
        <img src={logo15} alt="" />
        <img src={logo16} alt="" />
        <img src={logo17} alt="" />
        <img src={logo18} alt="" />
        <img src={logo19} alt="" />
        <img src={logo20} alt="" />
        <img src={logo21} alt="" />
        <img src={logo22} alt="" />
        <img src={logo23} alt="" />
        <img src={logo24} alt="" />
        <img src={logo25} alt="" />
        <img src={logo26} alt="" />
        <img src={logo27} alt="" />
        <img src={logo28} alt="" />
        <img src={logo29} alt="" />

        <img src={logo31} alt="" />
        <img src={logo32} alt="" />
        <img src={logo33} alt="" />
        <img src={logo34} alt="" />
        <img src={logo35} alt="" />
        <img src={logo36} alt="" />
        <img src={logo37} alt="" />
      </div>
    </div>
  );
}

export default Marquee;
