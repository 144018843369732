import React, { useEffect, useRef } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

// Register ScrollTrigger with GSAP
gsap.registerPlugin(ScrollTrigger);

const TitleAnimation = (props) => {
  const titleRef = useRef(null);

  // Function to split the text into individual characters
  const splitText = () => {
    const text = titleRef.current.innerText;
    titleRef.current.innerHTML = ""; // Clear the original text

    // Split the text into individual characters and wrap each in a span
    text.split("").forEach((char) => {
      const span = document.createElement("span");
      span.classList.add("char");
      span.innerText = char;
      titleRef.current.appendChild(span);
    });
  };

  useEffect(() => {
    // Split the text into characters on mount
    splitText();

    // Trigger GSAP animation on scroll using ScrollTrigger
    if (titleRef.current) {
      gsap.from(".title .char", {
        duration: 1,
        opacity: 0,
        stagger: 0.02,
        y: 30,
        ease: "power1.out",
        scrollTrigger: {
          trigger: titleRef.current, // Trigger animation when the title is in view
          start: "top 80%", // Start animation when top of title is at 80% of the viewport height
          end: "top 20%", // End when top of title reaches 30% of the viewport height
          scrub: true, // Optional: Smooth scroll effect
        },
      });
    }
  }, []);

  return (
    <h1 className={`title ${props.class}`} ref={titleRef}>
      {/* The text will be split into individual characters on mount */}
      {props.text}
    </h1>
  );
};

export default TitleAnimation;
